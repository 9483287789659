<template>
  <main class="dashboard admin-page bg-cream px-12 ml-auto">
    <div class="container min-h-screen flex flex-col pt-12 pb-20">
      <header class="admin-page-header mb-10">
        <h1 class="text-3xl text-blue">Dashboard</h1>
      </header>

      <Loader :isloading="loading" />

      <ul v-if="!loading" class="dashboard-infos grid flex-1">
        <li class="dashboard-info text-blue text-sm pt-4" v-for="metric in dashboard" :key="metric.title">
          {{ metric.title }} <span class="text-5xl block mt-6">{{ metric.value }}</span>
        </li>
      </ul>

      
    </div>
  </main>
</template>

<script>
import api from '@/utils/api.js';
import Loader from '@/components/Loader';


export default {
  name: "Admin",
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
      dashboard: [],
    }
  },
  mounted() {
    this.fetchDashboard();
  },
  methods: {
    async fetchDashboard() {
      this.loading = true;
      const response = await api.get('dashboard');
      this.dashboard = response.data;
      this.loading = false;
    },
  }
};
</script>

<style lang="scss">
.dashboard-infos {
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 70px;
  grid-template-rows: 1fr;
}

.dashboard-info {
  border-top: 1px solid rgba(246, 155, 139, 0.2);
}
</style>
